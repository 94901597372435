import React from 'react';
import { useTranslation } from 'react-i18next';
import LeftAlienedImageCard from './LeftAlienedImageCard/LeftAlienedImageCard';
import RightAlienedImageCard from './RightAlienedImageCard/RightAlienedImageCard';

const AllServices = () => {
  const { t } = useTranslation();

  const data = (string, returnObjects = false) => {
    return t(`services.${string}`, {
      returnObjects: returnObjects,
    });
  };

  const services = data('allServices', true);
  const servicesArray = Array.isArray(services) ? services : [];

  return (
    <div className="bg-white   max-w-5xl mx-auto ">
      {servicesArray.map((service, index) => (
        <div className="flex flex-col  justify-center items-center">
          <RightAlienedImageCard data={service.cardOne} />
          <LeftAlienedImageCard data={service.cardTwo} />
        </div>
      ))}
    </div>
  );
};

export default AllServices;
